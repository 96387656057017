import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)/(home)": [~5,[2],[3]],
		"/(app)/actions": [~6,[2],[3]],
		"/(app)/cookies": [7,[2],[3]],
		"/(app)/courses": [~8,[2],[3]],
		"/(app)/framework/applications": [~9,[2],[3]],
		"/(app)/framework/reports/dashboard": [~10,[2],[3]],
		"/(app)/framework/reports/dashboard/[id]": [~11,[2],[3]],
		"/(app)/framework/reports/view/[id]": [~12,[2],[3]],
		"/login": [~22],
		"/(app)/mi-auth0-tool": [~13,[2],[3]],
		"/(app)/newsletters": [~14,[2],[3]],
		"/(app)/notifications": [~15,[2],[3]],
		"/(app)/policies": [~16,[2],[3]],
		"/policies/[policy=policy]": [~23,[4]],
		"/(app)/redeem": [~17,[2],[3]],
		"/(app)/resources": [~18,[2],[3]],
		"/(app)/settings": [~19,[2],[3]],
		"/(app)/setup": [~20,[2],[3]],
		"/(app)/support": [21,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';